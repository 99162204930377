<template>
  <div class="application">
    <!-- kv -->
    <section
      id="kv_content"
      :style="{ backgroundImage: `url( ${require('@/statics/img/banner/apply.webp')} )` }"
    >
      <div class="kv_title txt-white">
        <h1>APPLICATION</h1>
        <h2>入會申請</h2>
      </div>
    </section>

    <!-- current stage -->
    <section
      id="stage"
      :style="{ backgroundImage: `url( ${require('@/statics/img/index/kv_bg.jpg')} )` }"
    >
      <div class="w1300">

        <ApplicationStage />

      </div>
    </section>

    <!-- application description -->
    <section id="apply_description">
      <div class="w1300">

        <p class="txt-center">
          <span id="user_name">{{ realname }}</span> 您好，<br><br>
          <template v-if="applyStatus === '審核通過'">
            <span class="txt-bold">恭喜您已通過資料審核，</span><br><br>
            請選擇繳費方式，並盡快繳納入會費用。
          </template>
          <template v-else-if="applyStatus === '繳費失敗'">
            <span class="txt-bold">由於信用卡繳費失敗</span><br><br>
            請重新繳費。
          </template>
          <template v-else-if="applyStatus === '實體通路繳費'">
            <span>您的繳費資料如下，</span><br><br>
            繳費後即可登入會員專區使用各項功能。
          </template>
        </p>

      </div>
    </section>

    <!-- application form detail -->
    <section id="form_detail" class="mg-b-50">
      <div class="w1000">

        <div class="payment_detail">
          <h6 class="payment_detail_title txt-bold">入會費用明細</h6>
          <!-- table area -->
          <div class="table_box">
            <table class="txt-center">
              <thead>
                <tr>
                  <th class="td-main txt-left"><p>{{ tableHead1 }}</p></th>
                  <th class="min180"><p>{{ tableHead2 }}</p></th>
                  <th class="min100"><p>{{ tableHead3 }}</p></th>
                </tr>
              </thead>
              <tbody id="table_data">
                <tr v-for="tr in tableData" :key="tr.uuid">
                  <td :data-title="tableHead1" class="td-main txt-left"><p>{{ tr.content }}</p></td>
                  <td :data-title="tableHead2" class="td-input">
                    <!-- <p>{{ realname }}</p> -->
                    <input type="text" placeholder="請輸入收據抬頭" v-model="tr.title">
                  </td>
                  <td :data-title="tableHead3"><p>{{ tr.price }}</p></td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
        <div class="payment_check txt-right" v-if="applyStatus !== '實體通路繳費'">
          <p class="payment_check_total">
            總金額 <span class="txt-light_green">{{ totalAmount }}</span> 元
          </p>
          <form id="form_payment" @submit.prevent>
            <div class="payment_check_container">
              <Select
                id="pay_type"
                v-model="user.pay_id"
                :item="inputFormat.paymentSelect"
                :sysVar="payType"
                :rules="[
                  val => !!val || '必選欄位！',
                ]"
              />

              <!-- 信用卡繳費：綠界金流 -->
              <button
                type="button"
                class="btn p"
                @click="onSubmit"
              >前往繳費</button>
            </div>

          </form>
        </div>

      </div>
    </section>

    <!-- application description -->
    <section id="apply_description" v-show="applyStatus === '實體通路繳費'">
      <div class="w1300">

        <div class="success_info txt-center">

          <div class="success_detail txt-left" v-show="applyPaymentType === 'ATM轉帳'">
            <p class="success_detail_title h6 txt-bold">請透過ATM轉帳至以下銀行帳號以完成繳費</p>
            <div class="success_detail_content p">
              <ul class="success_detail_content_title">
                <li>繳費銀行代碼</li>
                <li>繳費帳號</li>
                <li>付款金額</li>
                <li>繳費期限</li>
              </ul>
              <ul class="success_detail_content_text">
                <li>822</li>
                <li>9103522175887271</li>
                <li class="txt-bold"><span>2000</span>元</li>
                <li class="txt-red txt-bold">2020/09/10 08:00</li>
              </ul>
            </div>
          </div>

          <div class="success_detail txt-left" v-show="applyPaymentType === '超商代碼繳費'">
            <p class="success_detail_title h6 txt-bold">請至7-11/全家/萊爾富/OK超商 多媒體機台列印繳費單繳費</p>
            <div class="success_detail_content p">
              <ul class="success_detail_content_title">
                <li>超商繳費代碼</li>
                <li>付款金額</li>
                <li>繳費期限</li>
              </ul>
              <ul class="success_detail_content_text">
                <li>GW130412257496</li>
                <li class="txt-bold"><span>2000</span>元</li>
                <li class="txt-red txt-bold">2020/09/10 08:00</li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
import '@/assets/scss/application.scss';

import ApplicationStage from '@/components/ApplicationStage.vue';
import Select from '@/components/form/Select.vue';

import { mapGetters, mapActions } from 'vuex';
import { userInfo, applyOrders } from '@/lib/http';
import _ from 'lodash';

export default {
  title: '入會申請',
  name: 'ApplicationPayment',
  data() {
    return {
      /** Static Data */
      tableHead1: '費用名稱',
      tableHead2: '發票抬頭',
      tableHead3: '金額',
      inputFormat: {
        paymentSelect: {
          placeHolder: '請選擇繳費方式',
          options: [
            '信用卡',
            // 'ATM轉帳',
            // '超商代碼繳費',
          ],
        },
      },
      /** Axios Data */
      realname: '',
      applyStatus: '審核通過',
      applyPaymentType: '',
      tableData: [],
      user: {
        order_uuid: '',
        pay_id: 1,
        detail: [],
      },
      defaultTitle: '',
    };
  },
  created() {
    if (this.hasToken) {
      userInfo().then((result) => {
        this.realname = _.get(result, 'realname', '註冊會員');
        const userApply = parseInt(_.get(result, 'apply', null), 10);
        const userLevel = parseInt(_.get(result, 'level', null), 10);
        const userIdNum = _.get(result, 'id_number', null);
        this.defaultTitle = _.get(result, 'memo.fee_title', null);

        /** 審核通過的非會員醫師才可進入此頁流程 */
        if (userApply === 2 && userLevel === 3) {
          applyOrders({ id_number: userIdNum }).then((res) => {
            this.tableData = _.get(res, 'order.order_details', []);
            this.user.order_uuid = _.get(res, 'order.uuid', '');
            if (this.defaultTitle) {
              this.tableData.forEach((item) => {
                // eslint-disable-next-line no-param-reassign
                item.title = this.defaultTitle;
              });
            }
          });
        } else {
          this.openModal({
            url: '/application/index',
            message: '請先至審核狀態查詢輸入身分證字號/居留證號碼',
          });
        }
      });
    } else {
      this.openModal({
        url: '/application/index',
        message: '請先至審核狀態查詢輸入身分證字號/居留證號碼',
      });
    }
  },
  computed: {
    ...mapGetters([
      'hasToken',
      'payType',
    ]),
    totalAmount() {
      let computedPrice = 0;
      this.tableData.forEach((data) => {
        computedPrice += data.price;
      });
      return computedPrice;
    },
    paymentData() {
      const target = [];
      for (let i = 0; i < this.tableData.length; i += 1) {
        const item = this.tableData[i];
        target.push({
          uuid: item.uuid,
          title: item.title,
        });
      }
      return target;
    },
  },
  methods: {
    ...mapActions([
      'openModal',
    ]),
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        this.user.detail = this.paymentData;
        this.$cookies.set('applicationPayment', JSON.stringify(this.user));
        if (this.$cookies.get('applicationPayment')) {
          this.$router.push({ path: '/application/confirm' });
        }
      } else {
        this.openModal({
          message: '請選擇繳費方式',
        });
      }
    },
  },
  components: {
    ApplicationStage,
    Select,
  },
};
</script>
